/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    dash: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 8a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7A.5.5 0 014 8"/>',
    },
});
